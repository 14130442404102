'use strict';

/*
  This script handles the form submit and validation.
*/

var _gametemplate = _gametemplate || {};

_gametemplate.Module = function () {



	//init the template
	var init = function () {
		initOrientationCheck();
		onFormSubmit();
		formatDOB();
		displayCookieBannerOneTime();
	};



	var initOrientationCheck = function () {

		//check for device orientation
		if (window.DeviceOrientationEvent) {
			window.addEventListener('orientationchange', doOnOrientationChange);
			// Initial execution if needed
			doOnOrientationChange();
		} else {
			console.log("Sorry, your browser doesn't support Device Orientation");
		}
	};

	var displayCookieBannerOneTime = function(){
		if(!localStorage.getItem('cookie')) {
		  $('.cookie__policy').css('display','flex');
		}
	};

	var hideCookiePolicy = function(){
		localStorage.setItem('cookie', 1);
		$('.cookie__policy').hide();
	};



	var onTermsCheck = function () {
		$('#accept').on('click', function (event) {
			$('#termsmodal').modal('show');
			event.preventDefault();
			event.stopPropagation();
			return false;
		});

	};




	var acceptTerms = function (v) {
		closeModal();
		document.getElementById('terms').checked = true;
	};

	var declineTerms = function (v) {
		closeModal();
		document.getElementById('terms').checked = false;
	};


	var termsHint = function () {
		$('#termsHint').modal('hide');
	};

	var invalidCode = function () {
		$('#invalid_code').modal('hide');
	};

	var reload = function () {
		window.location.reload();
	};

	var onTermsClick = function () {
		openModal('termsPopup');
	};

	var validateEmail = function (email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	var validateDOB = function (dob) {
		var re = /^((0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3})/;
		return re.test(dob);
	}

	var validateToken = function (token) {
		var re = /^[a-zA-Z0-9]{8}$/;
		return re.test(token);

	}

	var onFormSubmit = function () {
		$("#claim_form").on("submit", function (e) {
			//e.preventDefault();
			return validCheck();
		})
	};

	var validCheck = function () {
		var isValid = true;

		var firstName = $('input[name=firstName]').val().trim();
		var lastName = $('input[name=lastName]').val().trim();
		var email = $('input[name=email]').val().trim();
		var dob = $('input[name=dob]').val().trim();
		var token = $('input[name=token]').val().trim();

		if (!(firstName && firstName.length > 2 && firstName.length < 50 && !validateEmail(firstName))) {

			isValid = (isValid) ? false : isValid;
			toggleErrorMessage('input[name=firstName]', true);

		} else {

			toggleErrorMessage('input[name=firstName]', false);

		}

		if (!(lastName && lastName.length > 2 && lastName.length < 50 && !validateEmail(lastName))) {

			isValid = (isValid) ? false : isValid;
			toggleErrorMessage('input[name=lastName]', true);

		} else {

			toggleErrorMessage('input[name=lastName]', false);

		}


		if (!validateEmail(email)) {

			isValid = (isValid) ? false : isValid;
			toggleErrorMessage('input[name=email]', true);

		} else {

			toggleErrorMessage('input[name=email]', false);

		}

		if (!validateDOB(dob)) {

			isValid = (isValid) ? false : isValid;
			toggleErrorMessage('input[name=dob]', true);

		} else {

			toggleErrorMessage('input[name=dob]', false);

		}

		if (!validateToken(token)) {

			isValid = (isValid) ? false : isValid;
			toggleErrorMessage('input[name=token]', true);

		} else {

			toggleErrorMessage('input[name=token]', false);
		}

		if (!$('#terms').is(':checked')) {
			$('.terms_group').addClass('shake animated');
			setTimeout(function () {
				$('.terms_group').removeClass('shake animated');
			}, 700);
			isValid = (isValid) ? false : isValid;

		}

		if ($("#g-recaptcha-response").val() === "") {

			$('.g-recaptcha').addClass('shake animated');
			setTimeout(function () {
				$('.g-recaptcha').removeClass('shake animated');
			}, 700);


			isValid = (isValid) ? false : isValid;
		}


		return isValid;
	};

	var toggleErrorMessage = function (domm, visible) {

		var errorMessage = $(domm).parent().find('.form__span-error');
		var errorFeild = $(domm).parent().find('input');

		if (visible) {
			errorFeild.addClass('form__input--error');
			errorMessage.css('display', 'block');

		} else {
			errorFeild.removeClass('form__input--error');
			errorMessage.hide();
		}
	};

	var formatDOB = function () {
		$("input[name=dob]").bind("keyup change", function (e) {
			var v = this.value;
			if (v.match(/^\d{2}$/) !== null) {
				this.value = v + '/';
			} else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
				this.value = v + '/';
			}
		});

	};

	//orientation check
	var doOnOrientationChange = function () {
		switch (window.orientation) {
			case -90:
			case 90:

				if (window.innerWidth < 768) {
					console.log(window.innerWidth)
					$('.orientation').show();
				}
				break;

			default:
				$('.orientation').hide();
				break;
		}
	};


	var setViewPort = function () {
		setTimeout(function () {
			var viewheight = $(window).height();
			var viewwidth = $(window).width();
			var viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
		}, 300);
	};




	var closeterms = function () {
		$('#buyonegetone').modal('hide');
		$('#freepudding').modal('hide');
		$('#freedrink').modal('hide');
		$('#freestarter').modal('hide');
		//$('#prize_voucher').modal('show');
	};





	//public functions
	var oPublic = {
		init: init,
		onTermsClick: onTermsClick,
		acceptTerms: acceptTerms,
		declineTerms: declineTerms,
		reload: reload,
		termsHint: termsHint,
		invalidCode: invalidCode,
		closeterms: closeterms,
		hideCookiePolicy:hideCookiePolicy
	};

	return oPublic;


}();

_gametemplate.Module.init();
