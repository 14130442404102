
var modalOpen = false;

/**
 * Opens the specified modal by add the show class
 * @param {string} modalId The modal to open
 */
function openModal(modalId) {
	if (event) {
		event.preventDefault();
	}
	console.log(document.getElementById(modalId));
	document.getElementById(modalId).classList.add('modal--show');
	document.getElementById('modalBackdrop').classList.add('modal__backdrop--show');
	modalOpen = true;

	//document.body.classList.add('no-scroll');
}

/**
 * Gets all modals on the page and closes them
 */
function closeModal() {
	const modalList = document.getElementsByClassName('modal');

	for (var i = 0; i < modalList.length; i++) {
		modalList[i].classList.add('modal--hide');

		
		(function(index) {
	        setTimeout(function(){
	        	console.log(modalList[index].classList);
				modalList[index].classList.remove('modal--show', 'modal--hide');
			}, 250);
	    })(i);
		
	}
	modalOpen = false;
	document.getElementById('modalBackdrop').classList.remove('modal__backdrop--show');
	//document.body.classList.remove('no-scroll');
}

/**
 * Detects escape key press and closes modal
 */
// document.onkeydown = function(evt) {
//     evt = evt || window.event;
//     if (evt.keyCode == 27 && modalOpen) {
//         closeModal();
//     }
// };
